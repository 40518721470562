<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                <div class="vx-logo flex justify-items-center items-center mb-2 pt-4">
                  <img src="@/assets/images/logo/logo.png" alt="logo" class="mx-auto" style="max-height: 25px"/>
                </div>
              <div class="p-4">
                <div class="vx-card__title mb-8 grid justify-items-center">
                  <h4 class="mb-4">{{ $t('certified_user.validate_title') }}</h4>
                  <p>{{ $t('certified_user.validate_subtitle') }}</p>
                </div>

                <vs-input
                  type="text"
                  id="validate_code"
                  name="validate_code"
                  v-validate="'required'"
                  :placeholder="$t('certified_user.validation_enter_code')"
                  :label="$t('certified_user.validation_code')"
                  :data-vv-as="$t('certified_user.validation_code')"
                  v-model="certified.validation_code"
                  v-on:keyup="clearData"
                  class="w-full pb-2 pt-2"
                  ref="validate_code"/>
                <span class="text-danger text-sm">{{ errors.first('validate_code') }}</span>
                <div v-if="isValidated">
                  <vs-input
                    type="text"
                    id="certified_name"
                    name="certified_name"
                    disabled
                    v-model="certified.certified_name"
                    :label="$t('certified_user.certified_name')"
                    class="w-full pb-2 pt-2"/>
                  <vs-input
                    type="text"
                    id="institution_name"
                    name="institution_name"
                    disabled
                    v-model="certified.institution_name"
                    :label="$t('certified_user.institution_name')"
                    class="w-full pb-2 pt-2"/>
                  <vs-input
                    type="text"
                    id="date_emit"
                    name="date_emit"
                    disabled
                    v-model="certified.date_emit"
                    :label="$t('certified_user.date_emit')"
                    class="w-full pb-2 pt-2"/>
                  <vs-input
                    type="text"
                    id="emitted_to"
                    name="emitted_to"
                    disabled
                    v-model="certified.user_name"
                    :label="$t('certified_user.emitted_to')"
                    class="w-full pb-2 pt-2"/>
                </div>
                <div class="flex flex-wrap justify-center sm:flex-row mt-8">
                  <vs-button
                    class="w-full sm:w-auto mb-4 mt-4 mr-4 ml-4 sm:mb-auto sm:mt-auto"
                    :disabled="!validateForm"
                    @click="validateCertifiedChangeUrl">{{ $t('certified_user.validate') }}</vs-button>
                  <vs-button
                    type="border"
                    to="/login"
                    class="w-full sm:w-auto mb-4 mt-4 mr-4 ml-4 sm:mb-auto sm:mt-auto">{{ $t('common.back') }}</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import CertifiedUserService from '@/services/api/CertifiedUserService'

export default {
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      certified: {
        validation_code: this.code,
        date_emit: '',
        certified_name: '',
        institution_name: '',
        user_name: ''
      },
      isValid : false,
      service: {
        certifiedUserService: null
      }
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && !this.isEmpty(this.certified.validation_code)
    },
    isValidated() {
      return this.certified.date_emit !== ''
    }
  },
  methods: {
    validateCertified() {
      //Loading
      this.$vs.loading()
      this.service.certifiedUserService.validateCertified(this.certified.validation_code).then(
        data => {
          this.$vs.loading.close()
          this.notifySuccess(this.$vs, this.$t('validation_yes'), 5000)
          this.certified.date_emit = data.date_emit
          this.certified.certified_name = data.certified_name
          this.certified.institution_name = data.institution_name
          this.certified.user_name = data.user_name
        },
        error => {
          this.$vs.loading.close()
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('validation_no'), 5000)
          this.certified.date_emit = ''
        }
      )//End
    },
    clearData() {
      this.certified.date_emit = ''
      this.certified.certified_name = ''
      this.certified.institution_name = ''
      this.certified.user_name = ''
    },
    validateCertifiedChangeUrl() {
      //If equals
      const location = window.location.toString()
      //if (location.indexOf(this.certified.validation_code) === -1) {
      this.$router.replace({ name: 'certified_user_validate', params: {code:this.certified.validation_code}})
      this.$router.go()
      //} else {
      //  this.$router.go()
      //}
    },
    goBack() {
      this.$store.dispatch('/login', {vs: this.$vs})
    }
  },
  beforeMount() {
    this.service.certifiedUserService = CertifiedUserService.build(this.$vs)
  },
  mounted() {
    this.validateCertified()
  }
}
</script>
