var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center",
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-logo flex justify-items-center items-center mb-2 pt-4",
                      },
                      [
                        _c("img", {
                          staticClass: "mx-auto",
                          staticStyle: { "max-height": "25px" },
                          attrs: {
                            src: require("@/assets/images/logo/logo.png"),
                            alt: "logo",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "p-4" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-card__title mb-8 grid justify-items-center",
                          },
                          [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v(
                                _vm._s(_vm.$t("certified_user.validate_title"))
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("certified_user.validate_subtitle")
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "validate_code",
                          staticClass: "w-full pb-2 pt-2",
                          attrs: {
                            type: "text",
                            id: "validate_code",
                            name: "validate_code",
                            placeholder: _vm.$t(
                              "certified_user.validation_enter_code"
                            ),
                            label: _vm.$t("certified_user.validation_code"),
                            "data-vv-as": _vm.$t(
                              "certified_user.validation_code"
                            ),
                          },
                          on: { keyup: _vm.clearData },
                          model: {
                            value: _vm.certified.validation_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.certified, "validation_code", $$v)
                            },
                            expression: "certified.validation_code",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("validate_code"))),
                        ]),
                        _vm.isValidated
                          ? _c(
                              "div",
                              [
                                _c("vs-input", {
                                  staticClass: "w-full pb-2 pt-2",
                                  attrs: {
                                    type: "text",
                                    id: "certified_name",
                                    name: "certified_name",
                                    disabled: "",
                                    label: _vm.$t(
                                      "certified_user.certified_name"
                                    ),
                                  },
                                  model: {
                                    value: _vm.certified.certified_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.certified,
                                        "certified_name",
                                        $$v
                                      )
                                    },
                                    expression: "certified.certified_name",
                                  },
                                }),
                                _c("vs-input", {
                                  staticClass: "w-full pb-2 pt-2",
                                  attrs: {
                                    type: "text",
                                    id: "institution_name",
                                    name: "institution_name",
                                    disabled: "",
                                    label: _vm.$t(
                                      "certified_user.institution_name"
                                    ),
                                  },
                                  model: {
                                    value: _vm.certified.institution_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.certified,
                                        "institution_name",
                                        $$v
                                      )
                                    },
                                    expression: "certified.institution_name",
                                  },
                                }),
                                _c("vs-input", {
                                  staticClass: "w-full pb-2 pt-2",
                                  attrs: {
                                    type: "text",
                                    id: "date_emit",
                                    name: "date_emit",
                                    disabled: "",
                                    label: _vm.$t("certified_user.date_emit"),
                                  },
                                  model: {
                                    value: _vm.certified.date_emit,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.certified, "date_emit", $$v)
                                    },
                                    expression: "certified.date_emit",
                                  },
                                }),
                                _c("vs-input", {
                                  staticClass: "w-full pb-2 pt-2",
                                  attrs: {
                                    type: "text",
                                    id: "emitted_to",
                                    name: "emitted_to",
                                    disabled: "",
                                    label: _vm.$t("certified_user.emitted_to"),
                                  },
                                  model: {
                                    value: _vm.certified.user_name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.certified, "user_name", $$v)
                                    },
                                    expression: "certified.user_name",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap justify-center sm:flex-row mt-8",
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "w-full sm:w-auto mb-4 mt-4 mr-4 ml-4 sm:mb-auto sm:mt-auto",
                                attrs: { disabled: !_vm.validateForm },
                                on: { click: _vm.validateCertifiedChangeUrl },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("certified_user.validate"))
                                ),
                              ]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "w-full sm:w-auto mb-4 mt-4 mr-4 ml-4 sm:mb-auto sm:mt-auto",
                                attrs: { type: "border", to: "/login" },
                              },
                              [_vm._v(_vm._s(_vm.$t("common.back")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }